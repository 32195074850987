<template>
  <v-container class="py-0 bg-glass-effect" fluid >
    <v-row class="bg-glass-effect">
      <BackArrow class="mt-4" :route="onClickBack()"/>

        <v-col class="mt-5" cols="12">
          <v-row class="">
            <v-col cols="12" class="py-0">
              <p style="margin-bottom: 0" class="text-center generic-title">
                {{ userName }}
              </p>
            </v-col>

            <v-col cols="12" class="py-0">
              <p style="margin-bottom: 0; color: #292867" class="text-center main-text">
                {{ generatedDateFormatted }}
              </p>
            </v-col>


            <v-col cols="12" class="pt-10 mt-10">
              <p style="margin-bottom: 0; background-color: white" class="text-center generic-title" v-html="qrCode">
              </p>
            </v-col>

          </v-row>
        </v-col>

    </v-row>
  </v-container>
</template>

<script>
import BackArrow from "@/components/covid/back-arrow";
import qrImage from 'qr-image'

export default {
  name: "qrCode",
  data: () => ({
    userName: "",
    generatedDateFormatted: "",
    exportData : "",
    qrCode : "",
    fromHistory: false
  }),
  components:{
    BackArrow
  },
  created () {
    this.loadRouteParams()
  },
  methods: {
    loadRouteParams(){
      this.fromHistory = this.$route.params.fromHistory;
      this.userName = this.$route.params.userName;
      this.generatedDateFormatted = this.$route.params.generatedDateFormatted;
      this.exportData = this.$route.params.exportData;
      this.qrCode = qrImage.imageSync(this.exportData , { type: 'svg',  });
    },
    onClickBack(){
      if(this.fromHistory){
        return 'historicoTriagem'
      }else {
        return 'miniHomeCovid'
      }
    }
  }
}
</script>

<style scoped>
  .bg-glass-effect {
    background: rgba(255, 255, 255, .6) !important;
    mix-blend-mode: normal !important;
    backdrop-filter: blur(100px) !important;
    height: 100%;
  }
</style>
